import React from 'react';
import { Link } from 'react-router-dom';
import {
  DDAAgreementLink,
  PathwardPrivacyPolicy,
  TFSBankAccountESignDisclosure
} from '@constants/pdfs';

const Footer = ({ text = '', textLink = '', link = '', isNotMain = false }) => {
  const additionalText = (
    <span className="font-link text-white desktop:text-darkGray">
      {text}{' '}
      <Link to={link} className="text-seafoam font-link font-bold">
        {textLink}
      </Link>
    </span>
  );

  return (
    <div className="flex flex-col justify-center items-center w-full gap-2">
      {text !== '' ? additionalText : ''}
      <div className="flex mobile:flex-col tablet:flex-row justify-around items-center w-full tablet:gap-8 mobile:gap-4 desktop:gap-8">
        <span
          className={`${
            isNotMain ? 'text-darkGray' : 'text-white'
          } desktop:text-darkGray desktop:text-footerElement tablet:text-dashBoardFooterTablet text-center`}>
          Customer Service: <a href="tel:866-357-6095">866-357-6095</a>
        </span>
        <a
          href={TFSBankAccountESignDisclosure}
          target="_blank"
          rel="noreferrer"
          className={`${
            isNotMain ? 'text-darkGray' : 'text-white'
          } desktop:text-darkGray text-footerElement text-center`}>
          Electronic Communications Agreement
        </a>
        <a
          href={PathwardPrivacyPolicy}
          target="_blank"
          rel="noreferrer"
          className={`${
            isNotMain ? 'text-darkGray' : 'text-white'
          } desktop:text-darkGray text-footerElement text-center`}>
          Pathward Privacy Policy
        </a>
        <a
          href={DDAAgreementLink}
          target="_blank"
          rel="noreferrer"
          className={`${
            isNotMain ? 'text-darkGray' : 'text-white'
          } desktop:text-darkGray text-footerElement text-center`}>
          Deposit Account Agreement
        </a>
      </div>
      <p
        className={`${
          isNotMain ? 'text-darkGray' : 'text-white'
        } desktop:text-darkGray text-footerElement text-center`}>
        YourSuccess Bank Account is a demand deposit account established by, and the YourSuccess
        Debit Card is issued by, Pathward®, N.A., Member FDIC. Mastercard® and the circles design
        are registered trademarks of Mastercard International Incorporated. Card is only valid for
        use in the U.S.
        <br />
        <br />
        Funds are FDIC insured, subject to applicable limitations and restrictions, when we receive
        the funds deposited to your account.
      </p>
      <span
        className={`${
          isNotMain ? 'text-darkGray' : 'text-white'
        } desktop:text-darkGray text-footerElement text-center`}>
        TFS {new Date().getFullYear()} © All Rights Reserved
      </span>
    </div>
  );
};

export default Footer;
